import axios from 'axios'

import { appConfig } from 'config'

import AppError from 'utils/error-handler'

export interface BaseRequest {
  source?: string
}

export interface StandardRequest<T = Record<string, any> | Record<string, any>[]> extends BaseRequest {
  data?: T
}

export interface Paginator {
  currentPage: number
  itemFrom: number
  itemTo: number
  limit: number
  nextPage: number | null
  previousPage: number | null
  totalItems: number
  totalPages: number
}

export interface ApiResponse<T = Record<string, any>> {
  statusCode?: number
  message: string
  data: T
  paginator?: Paginator
  status: string
}

export interface ApiErrorResponse<ErrorData = Record<string, any>> {
  status: string
  errorCode: number
  message: string
  error_detail: {
    code: string
    en_message: string
    id_message: string
    redirect_code: number
  }
  errors: string[]
  errorData: ErrorData
}

const request = axios.create({
  baseURL: appConfig.api.url,
  timeout: appConfig.api.timeout,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
})

request.interceptors.request.use(
  async (config) => {
    const newConfig = { ...config, headers: config?.headers ?? {} }

    // const isExpired = Number(expiredAt) <= Date.now()

    // const path = (newConfig.url)?.split('/') ?? []
    // const isApplicationPath = path?.[path.length - 1] === 'application'

    // if (isApplicationPath && isExpired) {
    //   try {
    //     const { data } = await axios.post(`${appConfig.api.url}/re-login`, { refresh_token: refreshToken })

    //     saveAuthCredential({ token: data?.data, user })
    //     newConfig.headers.Authorization = `Bearer ${data?.data.access_token}`

    //     return newConfig
    //   } catch (error) {
    //     console.log(error)
    //     return Promise.reject(error)
    //   }
    // }

    return newConfig
  },
  async (error) => {
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    return Promise.reject(new AppError(error))
  },
)

export function setTokenRequestHeader(accessToken?: string) {
  request.defaults.headers.common.authorization = `Bearer ${accessToken}`
}

export function removeTokenRequestHeader() {
  request.defaults.headers.common.authorization = ''
}

export default request
