import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { RootState } from 'redux/store'

interface IProps {
  element: any;
}

export const PrivateRoute: React.FC<IProps> = ({ element }) => {
  const user = useSelector((state: RootState) => state.user)
  const isLoggedIn = user.access_token && user.expired_at >= dayjs().unix()

  if (!isLoggedIn) return <Navigate to="/login" replace />
  return element
}
