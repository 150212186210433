import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface RequestState {
  loading?: boolean;
  error?: string | null;
  lastRequestAt?: number;
}

export const DEFAULT_REQUEST_STATE: RequestState = {
  loading: false,
  error: undefined,
  lastRequestAt: undefined,
}

export interface AppState {
  ready: boolean
  onboardVisited: boolean
  [actionTypePrefix: string]: RequestState | any;
}

const initialState: AppState = {
  ready: false,
  onboardVisited: false,
}

// Slice
export const appSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    initializeApp: (state) => {
      state.ready = true
    },
    setOnboardVisited(state, { payload: value }: PayloadAction<boolean>) {
      state.onboardVisited = value
    },
  },
})

export const {
  initializeApp, setOnboardVisited,
} = appSlice.actions

export default appSlice.reducer
