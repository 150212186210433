import { fetchAndActivate, getValue, getBoolean, getNumber, getString, getAll } from 'firebase/remote-config'
import { IS_PRODUCTION } from '@config/app.config'
import { remoteConfig } from './firebase'

type REMOTE_CONFIG_KEY = keyof typeof REMOTE_CONFIG_VALUES
type REMOTE_CONFIG_VALUE = typeof REMOTE_CONFIG_VALUES[REMOTE_CONFIG_KEY]

export const REMOTE_CONFIG_VALUES = {
  IS_SENTRY_ENABLED: 'is_sentry_enabled',
  IS_MAINTENANCE_MODE: 'is_maintenance_mode',
  REQ_PAYMENT_ERROR_MSG: 'req_payment_error_msg',
  OTHER_BANK_METHOD: 'other_bank_method',
  TOP_FIVE_BANK: 'top_five_bank',
} as const

remoteConfig.defaultConfig = {
  [REMOTE_CONFIG_VALUES.IS_SENTRY_ENABLED]: false,
  [REMOTE_CONFIG_VALUES.IS_MAINTENANCE_MODE]: false,
  [REMOTE_CONFIG_VALUES.REQ_PAYMENT_ERROR_MSG]: '',
  [REMOTE_CONFIG_VALUES.OTHER_BANK_METHOD]: '',
  [REMOTE_CONFIG_VALUES.TOP_FIVE_BANK]: '',
}

function initiateRemoteConfig() {
  fetchAndActivate(remoteConfig)
    .then(() => {
      if (!IS_PRODUCTION) console.log('Remote Config Successfully Activated')
    })
    .catch((err) => {
      if (!IS_PRODUCTION) console.log('Remote Config Activate Error', err)
    })
}

function getConfigValue(configValue: REMOTE_CONFIG_VALUE) {
  return getValue(remoteConfig, configValue)
}

function getConfigAsBoolean(configValue: REMOTE_CONFIG_VALUE) {
  return getBoolean(remoteConfig, configValue)
}

function getConfigAsNumber(configValue: REMOTE_CONFIG_VALUE) {
  return getNumber(remoteConfig, configValue)
}

function getConfigAsString(configValue: REMOTE_CONFIG_VALUE) {
  return getString(remoteConfig, configValue)
}

function getAllConfig() {
  return getAll(remoteConfig)
}

export { initiateRemoteConfig, getConfigValue, getConfigAsBoolean, getConfigAsNumber, getConfigAsString, getAllConfig }
