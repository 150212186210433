import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AuthResponse, removeSavedUserInfo } from 'utils/helpers/auth'

export interface UserModel {
  customer_id: string
  is_verified: boolean
  phone_number: string
  token: any
}

export interface UserState {
  authUser: any | null
  access_token: string
  expired_at: number
}

const initialState: UserState = {
  authUser: null,
  access_token: '',
  expired_at: 0,
}

// Slice
export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setAuthUser(state, { payload }: PayloadAction<UserModel | AuthResponse>) {
      state.authUser = payload
      state.access_token = 'isLoggedIn'
      state.expired_at = payload?.token?.expired_at
    },
    logout(state) {
      removeSavedUserInfo()
      state.authUser = null
      state.access_token = ''
      state.expired_at = 0
    },
  },
})

export const {
  setAuthUser,
  logout,
} = userSlice.actions

export default userSlice.reducer
