import { AxiosRequestConfig } from 'axios'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'

import request from 'utils/helpers/request'
import AppError from 'utils/error-handler'
import { handleRedirect } from 'utils/helpers/redirect-handler'

const axiosBaseQuery = (): BaseQueryFn<
  {
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
    headers?: AxiosRequestConfig['headers']
  },
  unknown,
  unknown

> => async ({ ...requestOpts }) => {
  try {
    const result = await request({
      ...requestOpts,
    })

    return { data: result.data }
  } catch (axiosError) {
    const err = axiosError as AppError
    if (err?.redirectCode === 1) {
      handleRedirect(err.redirectCode)
    }
    return {
      error: {
        status: err?.statusCode,
        data: err?.message,
      },
    }
  }
}

export const customBaseQuery = axiosBaseQuery()
