export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_FORMAT_2 = 'D MMM YYYY'

export const ERROR_MESSAGE = {
  ER001: 'Nomor telepon sudah terdaftar dan tidak bisa digunakan lagi',
  ER002: 'Email sudah terdaftar dan tidak bisa digunakan lagi',
  ER003: 'Nomor belum terdaftar. Lakukan pendaftaran di infinid.id/pre-qualify',
  ER004: 'Mohon maaf kami belum dapat melayani kamu karena kami belum menemukan bank yang sesuai dengan profil kamu',
  ER005: 'Kami sedang memproses pengajuan kamu. Mohon tunggu informasi lebih lanjut.',
  ER006: 'Silakan login kembali di infinid.id/login',
  ER007: 'Anda sudah melebihi batas percobaan',
  Default: 'Terjadi gangguan sistem, harap coba kembali nanti',
}

export const ERROR_MESSAGE_DESC = {
  PREQUALIFICATION_ALREADY_SUCCESS: 'Status prequalification anda telah berhasil',
  PREQUALIFICATION_NOT_READY_TO_SERVE: 'Mohon maaf kami belum dapat melayani Anda',
  PHONE_NUMBER_EXCEED_LIMIT: 'PhoneNumber cannot be longer than or equal to 16',
}

export const BYPASS_ERROR_LOGGING = [
  ERROR_MESSAGE_DESC.PREQUALIFICATION_ALREADY_SUCCESS,
  ERROR_MESSAGE_DESC.PREQUALIFICATION_NOT_READY_TO_SERVE,
  ERROR_MESSAGE_DESC.PHONE_NUMBER_EXCEED_LIMIT,
]

export const API_METHOD = {
  POST: 'POST',
  PUT: 'PUT',
  GET: 'GET',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
}
