import { AxiosError } from 'axios'
import * as Sentry from '@sentry/react'

import { API_METHOD, BYPASS_ERROR_LOGGING, ERROR_MESSAGE } from 'config/constants'
import { logErrorToSentry } from '@config/sentry'
import { ApiErrorResponse } from './helpers/request'

type ErrorResponse = {
  status: string
  message: string
  error_detail: {
    id_message: string
    en_message: string
    redirect_code: number
    code: string
  }
  error_code: string
  errors: string[]
  fields?: string[]
}

export type AppErr = Partial<AxiosError<ErrorResponse>> & Error

interface ErrorOptions {
  shouldReport?: boolean
  debugMessage?: string
  errorCode?: string
}

export default class AppError {
  message?: string

  err: AppErr

  originalErrMsg: string

  debugMsg?: string

  redirectCode?: number

  messageBag: string[]

  statusCode?: number

  constructor(err: AppErr, { debugMessage, shouldReport = true }: ErrorOptions = {}) {
    this.err = err
    this.originalErrMsg = err.message
    this.debugMsg = debugMessage
    this.messageBag = []
    this.statusCode = this.isAxiosError() ? err?.response?.status ?? 500 : 500

    if (this.isAxiosError()) {
      const axiosErrorResponse = (this.err as AxiosError<ApiErrorResponse>).response
      const errorCode = axiosErrorResponse?.data?.error_detail?.code
      const errorMessageId = axiosErrorResponse?.data?.error_detail?.id_message
      this.message =
        errorCode === '' && errorMessageId === ''
          ? axiosErrorResponse?.data?.message
          : ERROR_MESSAGE[errorCode as keyof typeof ERROR_MESSAGE]
      this.redirectCode = axiosErrorResponse?.data?.error_detail?.redirect_code
      this.statusCode = axiosErrorResponse?.status ?? this.statusCode
      this.messageBag = axiosErrorResponse?.data?.errors ?? []
    }

    const errorMessage = this.err?.response?.data?.error_detail?.id_message || this.err?.response?.data?.message

    // only print error to console when not in production
    if (import.meta.env.VITE_ENVIRONMENT !== 'production') {
      this.printLog(this.err)
    }

    if (
      this.err?.config?.method?.toUpperCase() === API_METHOD.GET &&
      this.statusCode < 422 &&
      BYPASS_ERROR_LOGGING.includes(errorMessage || '')
    ) {
      // eslint-disable-next-line no-param-reassign
      shouldReport = false
    }
    if (shouldReport) logErrorToSentry(this.err)
  }

  isAxiosError = () => {
    return !!(this.err.response ?? this.err.request)
  }

  printLog = (error: AppErr) => {
    // @TODO check app env != prod
    console.log(
      '%c============================ Error! ============================',
      'color: #B72619; font-weight: bold',
    )
    console.log(`%cDebug message: %c${this.debugMsg}`, 'font-weight: bold', '')

    if (error.response?.config) {
      const { url, data, method } = error.response.config
      console.log(
        `%cRequested URL:%c [${method?.toUpperCase()}] ${url}`,
        'font-weight: bold',
        'color: #55B85E;font-weight: bold',
      )
      console.log('%cRequest data:', 'font-weight: bold')
      console.log(data)
      console.log('%cResponse body:', 'font-weight: bold')
      console.log(error.response.data)
      console.log('%cResponse headers:', 'font-weight: bold')
      console.log(error.response.headers)
      Sentry.setExtra('error_header', error.response.headers)
      Sentry.setExtra('error_data', error.response.data)
      Sentry.setExtra('request_info', { url, data, method })
    } else if (error.request) {
      console.log('%cAxios requested but no response is returned!', 'font-weight: bold')
      console.log(error.request._response)
      console.log(error.request)
      Sentry.setExtra('error_request', error.request)
      Sentry.setExtra('error_request_response', error.request._response)
    } else {
      console.log(error)
      Sentry.setExtra('error', error)
    }
    console.log(
      '%c================================================================',
      'color: #B72619; font-weight: bold',
    )
  }
}
