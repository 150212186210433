import Adjust from '@adjustcom/adjust-web-sdk'
import { IS_PRODUCTION } from './app.config'

const initiateAdjust = () => {
  Adjust.initSdk({
    appToken: import.meta.env.VITE_ADJUST_APP_TOKEN,
    environment: IS_PRODUCTION ? 'production' : 'sandbox',
    logLevel: IS_PRODUCTION ? 'error' : 'verbose',
 })
}

const PREQUALIFY_EVENT_PROPERTIES = {
  ONBOARDING_START: {
    TOKEN: 'yahbu0',
    PAGE_NAME: 'prequalify onboarding start',
  },
  PERSONAL_ACCOUNT_DATA: {
    TOKEN: '5atik7',
    PAGE_NAME: 'prequalify data akun - data akun',
  },
  PERSONAL_DATA_BIRTH_DATE: {
    TOKEN: 'qxwdbt',
    PAGE_NAME: 'prequalify data diri - tanggal lahir',
  },
  PERSONAL_DATA_KTP: {
    TOKEN: 'rcy21n',
    PAGE_NAME: 'prequalify data diri - nomor ktp',
  },
  PERSONAL_DATA_MARITAL_STATUS: {
    TOKEN: 'ctrdy3',
    PAGE_NAME: 'prequalify data diri - status pernikahan',
  },
  PERSONAL_DATA_SUBMIT: {
    TOKEN: '5866eg',
    PAGE_NAME: 'prequalify data diri - submit',
  },
  PROPERTY_DATA_PROPERTY_TYPE: {
    TOKEN: '6met81',
    PAGE_NAME: 'prequalify data jaminan - jenis properti',
  },
  PROPERTY_DATA_CERTIFICATE_OWNER: {
    TOKEN: 'iob5au',
    PAGE_NAME: 'prequalify data jaminan - pemilik sertifikat',
  },
  PROPERTY_DATA_CERTIFICATE_STATUS: {
    TOKEN: '9wkque',
    PAGE_NAME: 'prequalify data jaminan - status sertifikat',
  },
  PROPERTY_DATA_PROPERTY_ADDRESS: {
    TOKEN: 'q9so3g',
    PAGE_NAME: 'prequalify data jaminan - alamat properti',
  },
  PROPERTY_DATA_PROPERTY_VALUE: {
    TOKEN: 'v21o62',
    PAGE_NAME: 'prequalify data jaminan - nilai properti',
  },
  PROPERTY_DATA_SUBMIT: {
    TOKEN: 'cqynoc',
    PAGE_NAME: 'prequalify data jaminan - submit',
  },
  OCCUPATION_DATA_OCCUPATION_TYPE: {
    TOKEN: 'kh4zti',
    PAGE_NAME: 'prequalify data penghasilan - pekerjaan',
  },
  OCCUPATION_DATA_TOTAL_INCOME: {
    TOKEN: 'auvraj',
    PAGE_NAME: 'prequalify data penghasilan - jumlah penghasilan',
  },
  OCCUPATION_DATA_TOTAL_INSTALLMENT: {
    TOKEN: 'r5t0ij',
    PAGE_NAME: 'prequalify data penghasilan - jumlah angsuran',
  },
  OCCUPATION_DATA_SUBMIT: {
    TOKEN: '9fm2s5',
    PAGE_NAME: 'prequalify data penghasilan - submit',
  },
  LOAN_DATA_LOAN_PURPOSE: {
    TOKEN: '4boahf',
    PAGE_NAME: 'prequalify data pinjaman - tujuan dana',
  },
  LOAN_DATA_LOAN_AMOUNT: {
    TOKEN: 'vq9qj9',
    PAGE_NAME: 'prequalify data pinjaman - jumlah pinjaman',
  },
  LOAN_DATA_SUBMIT: {
    TOKEN: '6qibbg',
    PAGE_NAME: 'prequalify data pinjaman - submit',
  },
  LOAN_FACILITY_DATA_STATUS: {
    TOKEN: 'qystrf',
    PAGE_NAME: 'prequalify data fasilitas pinjaman - status pinjaman berjalan',
  },
  LOAN_FACILITY_DATA_HISTORY: {
    TOKEN: 'tdkg8e',
    PAGE_NAME: 'prequalify data fasilitas pinjaman - riwayat pinjaman',
  },
  LOAN_FACILITY_DATA_SUBMIT: {
    TOKEN: '6y8xc2',
    PAGE_NAME: 'prequalify data fasilitas pinjaman - submit',
  },
  SUBMITTED: {
    TOKEN: 'ym6tm5',
    PAGE_NAME: 'pre-qualification submit',
  },
}

const APPLICATION_EVENT_PROPERTIES = {
  ONBOARDING_START: {
    TOKEN: 'p916w9',
    PAGE_NAME: 'application data start',
  },
  PERSONAL_DATA_KK_UPLOAD: {
    TOKEN: '19ihb8',
    PAGE_NAME: 'application data informasi pribadi - upload kk',
  },
  PERSONAL_DATA_KTP_UPLOAD: {
    TOKEN: 'uegoq5',
    PAGE_NAME: 'application data informasi pribadi - upload ktp',
  },
  PERSONAL_DATA_SUBMIT: {
    TOKEN: 'z70c90',
    PAGE_NAME: 'application data informasi pribadi - submit',
  },
  PROPERTY_DATA_UPLOAD_RUMAH: {
    TOKEN: 'giondw',
    PAGE_NAME: 'application data informasi properti - photo rumah',
  },
  PROPERTY_DATA_UPLOAD_SERTIFIKAT: {
    TOKEN: 'kfiidj',
    PAGE_NAME: 'application data informasi properti - upload sertifikat',
  },
  PROPERTY_DATA_SUBMIT: {
    TOKEN: 'pbihrx',
    PAGE_NAME: 'application data informasi properti - submit',
  },
  ADDITIONAL_DATA_SUBMIT: {
    TOKEN: 'w4h7wb',
    PAGE_NAME: 'application data informasi data pendukung - submit',
  },
  SUBMITTED: {
    TOKEN: 'g2j681',
    PAGE_NAME: 'application data diajukan',
  },
  EKYC_SUBMIT: {
    TOKEN: 'qn0i0e',
    PAGE_NAME: 'application data - eKYC',
  },
  DIGITAL_SIGN_SUBMIT: {
    TOKEN: 't9nnp2',
    PAGE_NAME: 'application data - submit digital sign',
  },
}

const HOME_EVENT_PROPERTIES = {
  LOAN_SIMULATION_SUBMIT: {
    TOKEN: 'w28ft2',
    PAGE_NAME: 'loan simulation submit',
  },
}

export { initiateAdjust, PREQUALIFY_EVENT_PROPERTIES, APPLICATION_EVENT_PROPERTIES, HOME_EVENT_PROPERTIES }
