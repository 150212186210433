import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IBasicFormValues } from 'features/PreQualify/types'

export interface InitialStateProps {
  formStep: number;
  formValues: Record<string, any>;
  savedSubmitPrequalResp: Record<string, any>,
}

const initialState: InitialStateProps = {
  formStep: 0,
  formValues: {},
  savedSubmitPrequalResp: {},
}

export const prequalTypeFormSlice = createSlice({
  name: 'prequalTypeform',
  initialState,
  reducers: {
    setFormStep(state, { payload: step } : PayloadAction<number>) {
      state.formStep = step
    },
    // setBasicFormValues(state, { payload: { lineCode, formValue } }: PayloadAction<IBasicFormValues>) {
    //   console.log('lg jalan linecode = ', lineCode, formValue)
    setBasicFormValues(state, { payload: formValue }: PayloadAction<IBasicFormValues>) {
      state.formValues = { ...state.formValues, ...formValue }
    },
    deleteSpouseIncomeValue(state) {
      const newState = state.formValues
      delete newState.spouseIncome
      state.formValues = newState
    },
    handleResetPropertyType(state) {
      const newState = state.formValues
      delete newState.propertyType
      delete newState.propertyTypeDetail
      state.formValues = newState
    },
    handleResetCertificateType(state) {
      const newState = state.formValues
      delete newState.certificateType
      delete newState.certificateTypeDetail
      state.formValues = newState
    },
    handleResetCertificateOwner(state) {
      const newState = state.formValues
      delete newState.certificateOwner
      delete newState.certificateOwnerDetail
      delete newState.certificateOwnerAttendance
      state.formValues = newState
    },
    handleResetLoanPurpose(state) {
      const newState = state.formValues
      delete newState.fundPurposeDetail
      state.formValues = newState
    },
    handleResetIncomeResource(state) {
      const newState = state.formValues
      delete newState.businessType
      delete newState.legitimateBusiness
      delete newState.subIncomeResource
      delete newState.incomeResourceDetail
      state.formValues = newState
    },
    handleResetSubIncomeResource(state) {
      const newState = state.formValues
      delete newState.subIncomeResource
      state.formValues = newState
    },
    resetIsCreditHistory(state) {
      const newState = state.formValues
      delete newState.isShowCreditTable
      state.formValues = newState
    },
    resetToInitialState(state) {
      state.formStep = 0
      state.formValues = {}
      state.savedSubmitPrequalResp = {}
    },
    setSavedSubmitPrequalResp(state, { payload: submitPrequalResponse }: PayloadAction<IBasicFormValues>) {
      state.savedSubmitPrequalResp = { ...state.savedSubmitPrequalResp, ...submitPrequalResponse }
    },
  },
})

export const {
  setFormStep,
  setBasicFormValues,
  deleteSpouseIncomeValue,
  handleResetPropertyType,
  handleResetCertificateType,
  handleResetCertificateOwner,
  handleResetLoanPurpose,
  handleResetIncomeResource,
  handleResetSubIncomeResource,
  resetIsCreditHistory,
  resetToInitialState,
  setSavedSubmitPrequalResp,
} = prequalTypeFormSlice.actions

export default prequalTypeFormSlice.reducer
