import React, { lazy } from 'react'
import { Spin } from 'antd'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoute } from 'components/private-route'
import { useAppDispatch } from 'redux/hooks'
import { resetToInitialState } from 'redux/prequal-typeform/prequalTypeformSlice'
import { getConfigAsBoolean, REMOTE_CONFIG_VALUES } from '@utils/remote-config'

const Login = lazy(() => import('features/Auth'))
const CustomerApplicationPage = lazy(() => import('features/Application/Page'))
const PrivacyPolicyPage = lazy(() => import('features/PrivacyPolicy/Page'))
const TermsConditionsPage = lazy(() => import('features/TermsConditions/Page'))
const VidaSelfiePage = lazy(() => import('features/VidaSelfie/Page'))
const ApplicationOnboard = lazy(() => import('features/Application/components/application-onboard'))
const PreQualifyPage2 = lazy(() => import('features/PreQualify/Page2'))
const MaintenancePage = lazy(() => import('features/Maintenance/Page'))
const SimulationPage = lazy(() => import('features/Simulation/Page'))
const PaymentPage = lazy(() => import('features/Payment/Page'))
const EmbedTypeform = lazy(() => import('features/Typeform/EmbedTypeform'))

const RedirectToInfinid = () => {
  const dispatch = useAppDispatch()
  dispatch(resetToInitialState())
  window.location.href = import.meta.env.VITE_WEB_REDIRECT_URL as string
  return (
    <Spin
      tip="Harap tunggu yaa..."
      size="large"
      style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <div style={{ width: '100vw', height: '100vh' }} />
    </Spin>
  )
}

const Router: React.FunctionComponent = () => {
  const isMaintenanceMode = getConfigAsBoolean(REMOTE_CONFIG_VALUES.IS_MAINTENANCE_MODE)

  return (
    <BrowserRouter>
      <Routes>
        {!isMaintenanceMode ? (
          <>
            <Route path="/" element={<Navigate to="/redirect" replace />} />
            <Route path="/redirect" element={<RedirectToInfinid />} />
            <Route path="/application-onboard" element={<PrivateRoute element={<ApplicationOnboard />} />} />
            <Route path="/application" element={<PrivateRoute element={<CustomerApplicationPage />} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/pp" element={<PrivacyPolicyPage />} />
            <Route path="/pre-qualify" element={<PreQualifyPage2 />} />
            <Route path="/simulate" element={<SimulationPage />} />
            <Route path="/tc" element={<TermsConditionsPage />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/deposito" element={<EmbedTypeform formId={import.meta.env.VITE_TYPEFORM_FORM_ID} />} />
            {import.meta.env.VITE_ENVIRONMENT !== 'production' && <Route path="/selfie" element={<VidaSelfiePage />} />}
            <Route path="*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <Route path="*" element={<MaintenancePage />} />
        )}
      </Routes>
    </BrowserRouter>
  )
}

export default Router
