import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface LanguageState {
  language: string;
}

const initialState: LanguageState = {
  language: 'id',
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    changeLanguage: (state, { payload: value }: PayloadAction<string>) => {
      state.language = value
    },
  },
})

export const { changeLanguage } = languageSlice.actions

export default languageSlice.reducer
