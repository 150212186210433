import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IBoxFormUploaderOnClickGuideProps } from 'components/box-form-uploader'
import { APPLICATION_GUIDE, IGuide } from 'features/Application/ constants'

export interface IApplicationPageState {
  guide: IGuide
}

const initialState = {
  guide: {
    title: '',
    description: '',
    img: '',
  },
  isShowGuideModal: false,
}

export const applicationPageSlice = createSlice({
  name: 'application-page',
  initialState,
  reducers: {
    setGuide(state, { payload: { entityType, documentType } }: PayloadAction<IBoxFormUploaderOnClickGuideProps>) {
      state.guide = APPLICATION_GUIDE?.[entityType]?.[documentType] ?? APPLICATION_GUIDE?.customers?.identity_card
    },
    resetGuide(state) {
      state.guide = APPLICATION_GUIDE?.customers?.identity_card
    },
    setIsShowGuideModal(state, { payload: show }: PayloadAction<boolean>) {
      state.isShowGuideModal = show
    },
  },
})

export const {
  setGuide,
  resetGuide,
  setIsShowGuideModal,
} = applicationPageSlice.actions

export default applicationPageSlice.reducer
