import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { customBaseQuery } from './BaseQuery'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: retry(customBaseQuery, {
    maxRetries: 0,
  }),
  tagTypes: ['Application'],
  endpoints: () => ({}),
})
