import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

import React from 'react'
import { UserModel } from '@redux/user/userSlice'
import { AppErr } from '@utils/error-handler'
import { formDataToString } from '@utils/formatters'
import { IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING } from './app.config'

function initSentry() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    release: `infinid-web-customer@${import.meta.env.VITE_APP_VERSION}`,
    debug: IS_DEVELOPMENT,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/infinid\.id/, /^https:\/\/infinid\.cloud/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    enabled: IS_PRODUCTION || IS_STAGING,
  })
}

function logErrorToSentry(error: AppErr) {
  Sentry.withScope((scope) => {
    const userCookies = Cookies.get('user')
    if (userCookies) {
      const userData = JSON.parse(userCookies ?? '') as UserModel
      scope.setUser({ id: userData?.customer_id, phoneNumber: userData?.phone_number })
    }

    const axiosError = error
    const errorMessage = axiosError?.response?.data?.error_detail?.id_message || axiosError?.response?.data?.message
    axiosError.name = `[${axiosError?.response?.status}] ${errorMessage}`

    if (axiosError?.config?.data instanceof FormData) {
      const objectData = formDataToString(axiosError?.config?.data)

      scope.addBreadcrumb({
        type: 'info',
        level: 'info',
        category: 'payload',
        message: 'Payload',
        data: JSON.parse(objectData),
      })
    }

    scope.setLevel('error')

    Sentry.captureException(axiosError, {
      tags: {
        url: axiosError?.config?.url,
        statusCode: axiosError?.response?.status,
        errorType: 'api',
      },
    })
  })
}

export { initSentry, logErrorToSentry }
