// import { Typography } from 'antd'

import ktpImg from 'assets/imgs/guideimgs/KTP.jpg'
import kkImg from 'assets/imgs/guideimgs/KK.jpg'
import npwpImg from 'assets/imgs/guideimgs/NPWP.jpg'
import aktaNikahImg from 'assets/imgs/guideimgs/Akta Nikah.jpg'
import aktaCeraiImg from 'assets/imgs/guideimgs/Akta Cerai.jpg'
import putusanCeraiImg from 'assets/imgs/guideimgs/Putusan Cerai.jpg'
import aktaKematianImg from 'assets/imgs/guideimgs/Akta Kematian.jpg'
import suratIjinPraktekImg from 'assets/imgs/guideimgs/Surat Ijin Praktek.jpg'
import npwpBadanUsahaImg from 'assets/imgs/guideimgs/NPWP Badan Usaha.jpg'
import slipGajiImg from 'assets/imgs/guideimgs/Slip Gaji Karyawan.jpg'
import nibImg from 'assets/imgs/guideimgs/NIB.jpg'
import buktiBayarPBBImg from 'assets/imgs/guideimgs/Bukti Bayar PBB.jpg'
import shmImg1 from 'assets/imgs/guideimgs/SHM.jpeg'
import shmImg2 from 'assets/imgs/guideimgs/SHM 2.jpeg'
import shmImg3 from 'assets/imgs/guideimgs/SHM 3.jpeg'
import shmImg4 from 'assets/imgs/guideimgs/SHM 4.jpeg'
import shmImg5 from 'assets/imgs/guideimgs/SHM 5.jpeg'
import shmImg6 from 'assets/imgs/guideimgs/SHM 6.jpeg'
import shmImg7 from 'assets/imgs/guideimgs/SHM 7.jpeg'
import shmImg8 from 'assets/imgs/guideimgs/SHM 8.jpeg'
import imbImg from 'assets/imgs/guideimgs/IMB.jpg'
import spptImg from 'assets/imgs/guideimgs/SPPT.jpg'
import siupImg from 'assets/imgs/guideimgs/SIUP.jpg'
import aktaPendirianImg from 'assets/imgs/guideimgs/Akta Pendirian + SK Kemenkumham.jpg'
import aktaPerImgubahanNama from 'assets/imgs/guideimgs/Akta Perubahan Nama + SK Kemenkumham.jpg'
import laporanKeuangan6BulanImg from 'assets/imgs/guideimgs/Laporan keuangan 6 bulan terakhir.jpg'
import laporanKeuangan1TahunImg from 'assets/imgs/guideimgs/Laporan keuangan tahun sebelumnya.jpg'
import mutasiRekeningImg from 'assets/imgs/guideimgs/Mutasi Rekening.jpg'
import rabImg from 'assets/imgs/guideimgs/RAB.jpg'
import rencanaBisnisImg from 'assets/imgs/guideimgs/Rencana Bisnis.jpg'

export interface IGuide {
  title: string | JSX.Element | undefined
  description: string | JSX.Element | undefined
  img: any
}
export interface IApplicationGuide {
  customers: {
    identity_card: IGuide
    family_card: IGuide
    npwp: IGuide
    spouse_identity_card: IGuide
    marriage_certificate: IGuide
    divorce_certificate: IGuide
    spouse_death_certificate: IGuide
    divorce_verdict: IGuide
  }
  properties: {
    certificate_image: IGuide
    certificate_owner_identity_card: IGuide
    certificate_owner_spouse_identity_card: IGuide
    building_permit_image: IGuide
    land_and_building_tax_image: IGuide
    tax_return_payable_letter: IGuide
    video: IGuide
    image: IGuide
  }
  occupations: {
    practice_license: IGuide
    business_tax_identification_card: IGuide
    business_permit_document: IGuide
    business_registration_certificate: IGuide
    articles_of_incorporation: IGuide
    latest_financial_report: IGuide
    payslip: IGuide
    transaction_record: IGuide
    account_statement_last_six_months: IGuide
    income_support_document: IGuide
    shareholder_member_document: IGuide
    finance_report_last_year: IGuide
    finance_report_last_six_months: IGuide
    business_location_image: IGuide
    work_certificate: IGuide
    business_address: IGuide
  }
  loan_applications: {
    budget_estimation_plan: IGuide
    business_plan: IGuide
    tuition_fee: IGuide
    estimation_tuition_fee: IGuide
    letter_of_statement: IGuide
    medical_fee: IGuide
    estimation_medical_fee: IGuide
    medical_certificate: IGuide
    other_document_bill: IGuide
    estimation_fee: IGuide
    house_offers: IGuide
    house_purchase_plan_agreement: IGuide
    business_transaction_record: IGuide
    vehicle_offers: IGuide
    bill_document: IGuide
    statement_document: IGuide
    offers_document: IGuide
    agreement_document: IGuide
  }
  supports: {
    customers: IGuide,
    properties: IGuide,
    occupations: IGuide,
    loan_purpose: IGuide,
  }
  default: IGuide
}

const imgPlaceholder = 'https://placehold.co/600x400'

export const APPLICATION_GUIDE: IApplicationGuide | Record<string, any> = {
  customers: {
    identity_card: {
      title: 'KTP',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: ktpImg,
    },
    family_card: {
      title: 'Kartu Keluarga',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: kkImg,
    },
    npwp: {
      title: 'NPWP',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: npwpImg,
    },
    spouse_identity_card: {
      title: 'KTP Pasangan',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: ktpImg,
    },
    marriage_certificate: {
      title: 'Akta Nikah',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: aktaNikahImg,
    },
    divorce_certificate: {
      title: 'Akta Cerai',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: aktaCeraiImg,
    },
    spouse_death_certificate: {
      title: 'Surat Kematian',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: aktaKematianImg,
    },
    divorce_verdict: {
      title: 'Surat Putusan Cerai',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: putusanCeraiImg,
    },
  },
  properties: {
    land_and_building_tax_image: {
      title: 'Pajak Bumi Bangunan (PBB)',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: buktiBayarPBBImg,
    },
    certificate_image: {
      title: 'Sertifikat Properti',
      description: (
        <div>
          Pastikan
          {' '}
          <b>seluruh halaman sertifikat</b>
          {' '}
          berupa cover, isi sertifikat dari awal hingga akhir halaman diunggah. Klik lihat contoh untuk mengetahui caranya.
        </div>
      ),
      img: [shmImg1, shmImg2, shmImg3, shmImg4, shmImg5, shmImg6, shmImg7, shmImg8],
    },
    certificate_owner_identity_card: {
      title: 'KTP Pemilik Sertifikat',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: ktpImg,
    },
    certificate_owner_spouse_identity_card: {
      title: 'KTP Pasangan Pemilik Sertifikath',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: ktpImg,
    },
    building_permit_image: {
      title: 'IMB',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: imbImg,
    },
    tax_return_payable_letter: {
      title: 'Surat Tagihan PBB (SPPT)',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: spptImg,
    },
    video: {
      title: 'Video Rumah',
      description: 'Harap unggah satu file dengan format .mp4',
      img: undefined,
    },
    image: {
      title: 'Foto Keadaan Rumah',
      description: 'Harap unggah satu file dengan format .jpg, .jpeg, .png',
      img: undefined,
    },
  },
  supports: {
    customers: {
      title: 'Panduan checklist dokumen pemilik lainnya',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
      img: imgPlaceholder,
    },
    properties: {
      title: 'Panduan checklist dokumen properti lainnya',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
      img: imgPlaceholder,
    },
    occupations: {
      title: 'Panduan checklist dokumen sumber penghasilan lainnya',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
      img: imgPlaceholder,
    },
    loan_purpose: {
      title: 'Panduan checklist dokumen tujuan dana lainnya',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud',
      img: imgPlaceholder,
    },
  },
  occupations: {
    practice_license: {
      title: 'Surat Ijin Praktek',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: suratIjinPraktekImg,
    },
    business_tax_identification_card: {
      title: 'NPWP Badan Usaha',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: npwpBadanUsahaImg,
    },
    business_permit_document: {
      title: 'Surat Izin Usaha',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: siupImg,
    },
    business_registration_certificate: {
      title: 'TDP/NIB',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: nibImg,
    },
    articles_of_incorporation: {
      title: 'Akta Pendirian & Perubahan Terakhir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: aktaPendirianImg,
    },
    latest_financial_report: {
      title: 'Laporan Keuangan Terakhir/Data Keuangan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: laporanKeuangan6BulanImg,
    },
    payslip: {
      title: 'Slip gaji 3 bulan terakhir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: slipGajiImg,
    },
    transaction_record: {
      title: 'Mutasi Rekening 3 Bulan Terakhir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: mutasiRekeningImg,
    },
    account_statement_last_six_months: {
      title: 'Mutasi 6 Bulan Terakhir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: mutasiRekeningImg,
    },
    income_support_document: {
      title: 'Dokumen Pendukung Penghasilan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    shareholder_member_document: {
      title: 'Akta Perubahan Pengurus & Pemegang Saham Terakhir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: aktaPerImgubahanNama,
    },
    finance_report_last_year: {
      title: 'Laporan Keuangan Tahun Sebelumnya',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: laporanKeuangan1TahunImg,
    },
    finance_report_last_six_months: {
      title: 'Laporan Keuangan 6 Bulan Terakhir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: laporanKeuangan6BulanImg,
    },
    business_location_image: {
      title: 'Foto Lokasi Usaha',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    business_address: {
      title: 'Alamat Usaha',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    work_certificate: {
      title: 'Surat Keterangan Kerja',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
  },
  loan_applications: {
    budget_estimation_plan: {
      title: 'Anggaran Biaya (RAB)',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: rabImg,
    },
    business_plan: {
      title: 'Rencana Bisnis',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: rencanaBisnisImg,
    },
    tuition_fee: {
      title: 'Tagihan Biaya Pendidikan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    estimation_tuition_fee: {
      title: 'Perkiraan Tagihan Biaya Pendidikan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    letter_of_statement: {
      title: 'Surat Keterangan Penerimaan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    medical_fee: {
      title: 'Tagihan Biaya Medis',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    estimation_medical_fee: {
      title: 'Perkiraan Tagihan Biaya Kesehatan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    medical_certificate: {
      title: 'Surat Keterangan Dokter',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    other_document_bill: {
      title: 'Tagihan Lain',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    bill_document: {
      title: 'Dokumen Tagihan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    estimation_fee: {
      title: 'Rencana Biaya',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    house_offers: {
      title: 'Penawaran Rumah',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    house_purchase_plan_agreement: {
      title: 'Perjanjian Rencana Jual Beli',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    business_transaction_record: {
      title: 'Rekening Koran Perusahaan 6 Bulan Terakir',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    vehicle_offers: {
      title: 'Penawaran Kendaraan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    statement_document: {
      title: 'Surat Keterangan',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    offers_document: {
      title: 'Surat Penawaran',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
    agreement_document: {
      title: 'Surat Perjanjian',
      description: 'Bisa unggah banyak file dengan format .jpg, .jpeg, .png, .pdf',
      img: undefined,
    },
  },
  // default: {
  //   title: 'Selamat, Anda selangkah lebih dekat dengan impian Anda.',
  //   description: (
  //     <Typography.Text>
  //       <ul>
  //         <li>Menyiapkan dokumen dalam bentuk soft-copy</li>
  //         <li>Memiliki foto atau berada di lokasi properti yang akan digunakan sebagai jaminan</li>
  //         <li>Estimasi waktu: 15-30 menit</li>
  //       </ul>
  //     </Typography.Text>
  //   ),
  //   img: imgPlaceholder,
  // },
}

export const APPLICATION_STEP = ['Dokumen Pribadi', 'Dokumen Properti', 'Dokumen Pendukung']

export const PROPERTY_PHOTO_GUIDE = [
  { title: 'Foto Tampak Depan Rumah', img: imgPlaceholder },
  { title: 'Foto Jalan Depan Rumah', img: imgPlaceholder },
  { title: 'Foto Ruang Keluarga', img: imgPlaceholder },
  { title: 'Foto kamar mandi utama', img: imgPlaceholder },
  { title: 'Foto kamar tidur utama', img: imgPlaceholder },
]
