import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PaymentProps {
  destinationPaymentId: string
}

const initialState: PaymentProps = {
  destinationPaymentId: '',
}

// Slice
export const paymentSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {
    setDestinationPaymentId(state, { payload: value }: PayloadAction<string>) {
      state.destinationPaymentId = value
    },
    resetPaymentId(state) {
      state.destinationPaymentId = ''
    },
  },
})

export const {
  setDestinationPaymentId,
  resetPaymentId,
} = paymentSlice.actions

export default paymentSlice.reducer
