const THEME = {
  token: {
    borderRadius: 4,
    colorPrimary: '#0E61FF',
    fontFamily: '\'Raleway\', sans-serif',
    fontSize: 14,
  },
}

export default THEME
