import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import EN from './en.json'
import ID from './id.json'

i18next.use(initReactI18next).init({
  resources: {
    id: ID,
    en: EN,
  },
  fallbackLng: 'id',
  interpolation: {
    escapeValue: false,
  },
})

export default i18next
