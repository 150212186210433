import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import * as Sentry from '@sentry/react'

import appReducer from './app/appSlice'
import langReducer from './language/languageSlice'
import applicationPageReducer from './application-page/applicationPageSlice'
import prequalTypeformSlice from './prequal-typeform/prequalTypeformSlice'
import userReducer from './user/userSlice'
import formReducer from './form/formSlice'
import notificationReducer from './notification/notificationSlice'
import paymentReducer from './payment/paymentSlice'
import { apiSlice } from './api/apiSlice'

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
}

const persistedFormReducer = persistReducer(persistConfig, formReducer)
const persistedPrequalTypeform = persistReducer(persistConfig, prequalTypeformSlice)
const persistedLangReducer = persistReducer(persistConfig, langReducer)
const persistedUserReducer = persistReducer(persistConfig, userReducer)
const persistedPayment = persistReducer(persistConfig, paymentReducer)

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

export const store = configureStore({
  reducer: {
    app: appReducer,
    lang: persistedLangReducer,
    applicationPage: applicationPageReducer,
    form: persistedFormReducer,
    notification: notificationReducer,
    user: persistedUserReducer,
    prequalTypeform: persistedPrequalTypeform,
    payment: persistedPayment,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat(apiSlice.middleware),
  devTools: import.meta.env.VITE_ENVIRONMENT !== 'production',
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer)
  },
})

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)
