import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface NotificationModel {
  type: 'success' | 'rejected' | 'notQualified' | 'processing' | string
  visible: boolean,
  additional?: any,
  description?: string
  duration?: number
  title?: string
  width?: number
}

export interface NotificationState {
  notification: NotificationModel,
}

export const INITIAL_NOTIFICATION = {
  description: '',
  duration: 5000,
  title: '',
  type: '',
  visible: false,
  width: 500,
}

const initialState: NotificationState = {
  notification: INITIAL_NOTIFICATION,
}

// Slice
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification(state, { payload }: PayloadAction<NotificationModel>) {
      state.notification = { ...state.notification, ...payload }
    },
    forceCloseNotification(state) {
      state.notification = { ...state.notification, visible: false }
    },
  },
})

export const {
  setNotification,
  forceCloseNotification,
} = notificationSlice.actions

export default notificationSlice.reducer
