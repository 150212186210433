import React from 'react'
import { Helmet } from 'react-helmet'
import Router from './Router'

import 'utils/firebase'

const App: React.FunctionComponent = () => {
  return (
    <>
      {/* Facebook Pixel in Production Only */}
      {import.meta.env.VITE_ENVIRONMENT === 'production' && (
        <Helmet>
          <script>
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '214259644464760');
                fbq('track', 'PageView');
              `}
          </script>
          <noscript>
            {`<img
                  height="1"
                  width="1"
                  style="display:none"
                  src="https://www.facebook.com/tr?id=214259644464760&ev=PageView&noscript=1"
                  />`}
          </noscript>
        </Helmet>
      )}
      <Helmet>
        <script>
          {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="jFriYdXnmk6BFJ4neLCfsWWC5IfwQ2mQ";;analytics.SNIPPET_VERSION="4.16.1";
            analytics.load("${import.meta.env.VITE_SEGMENT_ANALYTICS_KEY}");
            analytics.page();
            }}();`}
        </script>
      </Helmet>
      <Router />
    </>
  )
}

export default App
