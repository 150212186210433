import {} from 'react-router-dom'

export function handleRedirect(redirectCode = 0) {
  setTimeout(() => {
    switch (redirectCode) {
    case 1:
      window.location.href = '/pre-qualify'
      break
      // add more redirect case later
    default:
      break
    }
  }, 1200) // allow message to be shown before redirect
}
