import { Col, Row, Spin } from 'antd'

const SuspensePage = () => {
  return (
    <Row justify="center" className="inf-padding-top-lg">
      <Col>
        <Spin />
      </Col>
    </Row>
  )
}

export default SuspensePage
