import Cookies from 'js-cookie'
// import { removeTokenRequestHeader } from './request'
import { UserModel } from 'redux/user/userSlice'

interface UserCredential {
  user?: any;
  token: Record<string, any>
}

export interface AuthResponse {
  user: UserModel
  token: Record<string, any>
}

export async function authWithGoogle() {
  // try {
  //   const resp = await request.get(API_URL.GET_GOOGLE_AUTH_URL)
  //   const googleAuthURL = resp.data.data.url
  //   window.location = googleAuthURL
  // } catch (e) {
  //   new AppError(e, 'Cannot login using google. Please try again')
  // }
}

export function removeSavedUserInfo() {
  Cookies.remove('access-token')
  Cookies.remove('refresh-token')
  Cookies.remove('expired-at')
  Cookies.remove('user')
  // removed until we have logging for logout customer
  // removeTokenRequestHeader()
}

export function saveUserData(user: UserModel) {
  Cookies.set('user', JSON.stringify(user))
}

export function saveAuthCredential({ token, user }: UserCredential) {
  if (token.access_token) Cookies.set('access-token', token.access_token)
  if (token.refresh_token) Cookies.set('refresh-token', token.refresh_token)
  if (token.expired_at) Cookies.set('expired-at', JSON.stringify(token.expired_at))
  if (user) Cookies.set('user', JSON.stringify(user))
}

export function onLoginSuccess(authResponse: AuthResponse) {
  saveAuthCredential(authResponse)
}

export function getAuthUser() {
  const user = JSON.parse(Cookies.get('user') ?? '{}') as UserModel
  const accessToken = Cookies.get('access-token')
  const refreshToken = Cookies.get('refresh-token')
  const expiredAt = Cookies.get('expired-at')

  return { accessToken, refreshToken, expiredAt, user }
}
