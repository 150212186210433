import axios from 'axios'
import axiosRetry from 'axios-retry'
import dayjs from 'dayjs'
import { startCase, camelCase } from 'lodash'
import { DATE_FORMAT } from '../config/constants'

export function unixToDateString(unix?: number, withTime = false, format = DATE_FORMAT, fallback = '-') {
  if (!unix) return fallback
  return dayjs(withTime ? unix : unix * 1000).format(format)
}

export function priceFormatter(number: any) {
  if (number === undefined || number === null || !Number(number)) return number
  return Math.round(number)?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export function currencyParser(currencyString: any) {
  return currencyString?.replace(/[^0-9]\s?|(\.*)/g, '')
}

export function currencyFormatter(amount: number, currency = 'Rp') {
  const formatter = priceFormatter(amount)
  return `${currency} ${formatter}`
}

export interface IFormatUrlToFile {
  url: string
  fileName?: string
}
export async function formatUrlToFile({
  url,
  fileName = 'file',
}: IFormatUrlToFile) {
  // handle convert file from aws url
  if (url?.includes('.s3')) {
    axiosRetry(axios, {
      retries: 3,
      retryCondition(error) {
        return error?.message === 'Network Error'
      },
    })
    try {
      const res = await axios({
        url,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
        responseType: 'blob',
      })

      let blob = res?.data
      let finalFileName = `${fileName}.${blob.type?.split('/')?.[1]}`

      // handle .mp4 aws url (somehow it will have text/xml as blob type)
      if (url?.includes('.mp4')) {
        blob = new Blob([blob], { type: 'video/mp4' })
        finalFileName = `${fileName}.mp4`
      }

      // handle .doc aws url (somehow it will have text/xml as blob type)
      if (url?.includes('.doc')) {
        blob = new Blob([blob], { type: 'application/msword' })
        finalFileName = `${fileName}.doc`
      }

      // handle blob type application/vnd.rar
      if (url?.includes('.rar')) {
        blob = new Blob([blob], { type: 'application/vnd.rar' })
        finalFileName = `${fileName}.rar`
      }

      return new File([blob], finalFileName, { type: blob.type })
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        window.location.reload()
      }
    }
  }

  const res = await fetch(url, {
    headers: { 'Access-Control-Allow-Origin': '*' },
    mode: 'no-cors',
  })

  const blob = await res.blob()
  let finalFileName = `${fileName}.${blob.type?.split('/')?.[1]}`

  // handle blob type application/msword
  if (blob.type === 'application/msword') {
    finalFileName = `${fileName}.doc`
  }

  // handle blob type application/vnd.rar
  if (blob.type === 'application/vnd.rar') {
    finalFileName = `${fileName}.rar`
  }

  return new File([blob], finalFileName, { type: blob.type })
}

export function formatSelectOption(options: any, forceStartCase?: boolean) {
  return options?.map(({ id, name }: Record<string, any> = {}) => ({ label: forceStartCase ? startCase(name?.toLowerCase()) : name, value: id }))
}

export function formatSelectOptionWithCode(options: any, forceStartCase?: boolean) {
  return options?.map(({ id, name, code }: Record<string, any> = {}) => ({ label: forceStartCase ? startCase(name?.toLowerCase()) : name, value: id, code }))
}

export function formatPhoneNumberPayloadBE(val: string | number | null | undefined) {
  if (val === 0 || val === '0') return '+62'
  if (val === 62 || val === '62') return '+62'
  if (val && String(val)?.[0] === '0') {
    return `+62${String(val)?.substring(1, String(val)?.length)}`
  }
  if (val && String(val)?.[0] === '6' && String(val)?.[1] === '2') {
    return `+62${String(val)?.substring(2, String(val)?.length)}`
  }

  return `+62${val}`
}

export const normalizeNumber = (value: string) => {
  return value.replace(/\D/g, '')
}

export const normalizeText = (value: string) => {
  return value.replace(/\d/g, '')
}

export const snakeToSlug = (value: string) => {
  return value.replace(/_/g, '-').toLowerCase()
}

export function toTitleCase(stringInput: string, defaultValue = '') {
  if (!stringInput) {
    return defaultValue
  }
  return startCase(camelCase(stringInput))
}

export const formDataToString = (formData: FormData) => {
  return JSON.stringify(Object.fromEntries(formData))
}
