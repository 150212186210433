import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IBasicFormValues } from 'features/PreQualify/types'

export type FormModel = Record<string, any>

export interface MonthlyFeeComparison {
  helocValue: number;
  creditCardValue: number;
  multiPurposeCreditValue: number;
  microCreditValue: number;
}

export interface FormState {
  sharedFields: FormModel
  monthlyFeeComparison: MonthlyFeeComparison | Record<string, unknown>,
  formStep: number,
  basicFormValue: Record<string, any>,
  isShowCreditTable: boolean | undefined,
  savedSubmitPrequalResp: Record<string, any>,
}

const initialState: FormState = {
  sharedFields: {},
  monthlyFeeComparison: {},
  formStep: 0,
  basicFormValue: {},
  isShowCreditTable: undefined,
  savedSubmitPrequalResp: {},
}

// Slice
export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setSharedFields(state, { payload: fields }: PayloadAction<FormModel>) {
      state.sharedFields = fields
    },
    setMonthlyFeeComparison(state, { payload: value }: PayloadAction<MonthlyFeeComparison>) {
      state.monthlyFeeComparison = value
    },
    setFormStep(state, { payload: step }: PayloadAction<number>) {
      state.formStep = step
    },
    setBasicFormValues(state, { payload: formValue }: PayloadAction<IBasicFormValues>) {
      state.basicFormValue = { ...state.basicFormValue, ...formValue }
    },
    deleteSpouseIncomeValue(state) {
      const newState = state.basicFormValue
      delete newState.spouseIncome
      state.basicFormValue = newState
    },
    deleteBusinessTypeValue(state) {
      const newState = state.basicFormValue
      delete newState.businessType
      state.basicFormValue = newState
    },
    deleteSubIncomeResource(state) {
      const newState = state.basicFormValue
      delete newState.subIncomeResource
      state.basicFormValue = newState
    },
    clearBasicFormValue(state) {
      state.formStep = 0
      state.basicFormValue = {}
      state.isShowCreditTable = undefined
      state.savedSubmitPrequalResp = {}
    },
    setShowCreditTable(state, { payload: show }: PayloadAction<boolean>) {
      state.isShowCreditTable = show
    },
    setSavedSubmitPrequalResp(state, { payload: submitPrequalResponse }: PayloadAction<IBasicFormValues>) {
      state.savedSubmitPrequalResp = { ...state.savedSubmitPrequalResp, ...submitPrequalResponse }
    },
  },
})

export const {
  setSharedFields,
  setMonthlyFeeComparison,
  setFormStep,
  setBasicFormValues,
  deleteSpouseIncomeValue,
  deleteBusinessTypeValue,
  deleteSubIncomeResource,
  clearBasicFormValue,
  setShowCreditTable,
  setSavedSubmitPrequalResp,
} = formSlice.actions

export default formSlice.reducer
