type Env = 'development' | 'staging' | 'production'
interface AppConfig {
  env?: Env
  api: {
    url: string
    timeout: number
  },
  googleTagId: string
}

const ENV = (import.meta.env.VITE_ENVIRONMENT ?? 'development') as Env

// eslint-disable-next-line import/no-mutable-exports
let config: AppConfig = {
  env: ENV,
  api: {
    url: 'https://api.infinid.cloud',
    timeout: 300000,
  },
  // TODO: store this in repo secrets instead (atm: it can't read env/secrets in environment github settings)
  googleTagId: 'GTM-TH2LPDHJ',
}

if (ENV === 'production') {
  config = {
    ...config,
    api: {
      url: 'https://api.infinid.id',
      timeout: 300000,
    },
    // TODO: store this in repo secrets instead (atm: it can't read env/secrets in environment github settings)
    googleTagId: 'GTM-PSCPCKJ',
  }
}

export const ENVIRONMENT = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
}

export const IS_PRODUCTION = import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.PRODUCTION
export const IS_STAGING = import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.STAGING
export const IS_DEVELOPMENT = import.meta.env.VITE_ENVIRONMENT === ENVIRONMENT.DEVELOPMENT

export default config
