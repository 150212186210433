import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { ConfigProvider, App as AntdApp } from 'antd'
import TagManager from 'react-gtm-module'

import App from 'app'
import THEME from 'config/theme'
import { store } from 'redux/store'
import config from 'config/app.config'
import { initiateRemoteConfig } from 'utils/remote-config'
import { initSentry } from '@config/sentry'
import { initiateAdjust } from '@config/adjust'

import { Suspense } from 'react'
import SuspensePage from 'pages/SuspensePage'

import reportWebVitals from './reportWebVitals'
import './assets/sass/style.scss'
import './i18n'

const container = document.getElementById('root')!
const root = createRoot(container)
const persistor = persistStore(store)

const gtmArgs = {
  gtmId: config?.googleTagId,
}

TagManager.initialize(gtmArgs)

initiateRemoteConfig()

initSentry()

initiateAdjust()

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConfigProvider theme={THEME}>
        <AntdApp>
          <Suspense fallback={<SuspensePage />}>
            <App />
          </Suspense>
        </AntdApp>
      </ConfigProvider>
    </PersistGate>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
